import React from 'react';
import './Beard.css';
import beardImage from './Beard.png';


function BeardComponent() {
  return (
    <div className="beard">
      <img src={beardImage} alt="Beard" className="beard" />
    </div>
  );
}

export default BeardComponent;