import React, { useState } from 'react';
import eyeImage from './Eye.png'; 
import './Eye.css';

export default function Eye() {
  const [rotation, setRotation] = useState(0);

  const handleClick = () => {
    setRotation(prevRotation => prevRotation + 360);
  };

  const imageStyle = {
    transform: `rotate(${rotation}deg)`
  };

  return (
    <img
      src={eyeImage}
      alt="Rotatable Eye"
      className="eye"
      style={imageStyle}
      onClick={handleClick}
    />
  );
}