import React from 'react';
import './Header.css'; // Import the CSS file for styling

function Header() {
  return (
    <header className="header">
      <div className="column">Blog</div>
      <div className="column">Projects</div>
      <div className="column">Contact</div>
    </header>
  );
}

export default Header;