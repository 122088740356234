import React from 'react';
import Beard from './components/beard/Beard';
import Eye from './components/eye/Eye';
import './App.css'
import Header from './components/header/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="centered-components">
        <div className="beard">
          <Beard />
        </div>
        <div className="eye">
          <Eye />
        </div>
      </div>
    </div>
  );
}

export default App;
